import { ApplicationConfig } from '@/config/applicationConfig';
import {
  RerakuBlogObject,
  CourseGenreObject,
  RerakuCourseCategoryObject,
  CustomerInformationObject,
  PrefectureAreaObject,
  PrefectureObject,
  RailroadLineObject,
  ShopObject,
  RailroadStationObject,
  CityObject,
  ColumnObject,
} from '@/graphql/generated/graphql';
import { buildUrlParamsString } from '@/utils/url';

export type PageNames = Readonly<{
  top: {};
  guide: {};
  card: {};
  education: {};
  about_rerakupay: {};
  news: {};
  'news/{id}': Pick<CustomerInformationObject, 'displayId' | 'title'>;
  column: {};
  'column/{id}': Pick<ColumnObject, 'displayId' | 'title'>;
  brand: {};
  'brand/reraku': {};
  'brand/spareraku': {};
  'brand/rerakupro': {};
  'brand/rerakupro/triathlon': {};
  'brand/bellepoc': {};
  'brand/ruamruam': {};
  'brand/katakori': {};
  blog: {};
  tokushoho: {};
  'studio': {};
  'studio/search': {};
  'studio/{slug}': Pick<ShopObject, 'slug' | 'name'>;
  'studio/{slug}/menu': Pick<ShopObject, 'slug' | 'name'>;
  'studio/{slug}/staff': Pick<ShopObject, 'slug' | 'name'>;
  'studio/{slug}/message': Pick<ShopObject, 'slug' | 'name'>;
  'studio/{slug}/blog': Pick<ShopObject, 'slug' | 'name'>;
  'studio/{slug}/blog/{id}': Pick<RerakuBlogObject, 'displayId' | 'title' | 'shopSlug' | 'shopName'>;
  'studio/{slug}/booking': Pick<ShopObject, 'slug' | 'name'>;
  'studio/{slug}/booking/step2': Pick<ShopObject, 'slug' | 'name'>;
  'studio/{slug}/booking/step3': Pick<ShopObject, 'slug' | 'name'>;
  'studio/{slug}/booking/complete': Pick<ShopObject, 'slug' | 'name'>;
  'studio/pref_area/{prefectureAreaId}': Pick<PrefectureAreaObject, 'id' | 'name'>,
  'studio/pref/{prefectureId}': Pick<PrefectureObject, 'id' | 'name'>,
  // prefectureIdとしてエイリアスを生成
  'studio/pref/{prefectureId}/city/{cityId}': Pick<CityObject, 'id' | 'cityName'> & Pick<PrefectureObject, 'id'>;
  'studio/railroad/{railroadLineId}': Pick<RailroadLineObject, 'id' | 'name'>;
  'studio/station/{railroadStationId}': Pick<RailroadStationObject, 'id' | 'name'>;
  'studio/course/{courseId}': Pick<CourseGenreObject, 'id' | 'name'>;
  'studio/course_category/{courseCategoryId}': Pick<RerakuCourseCategoryObject, 'courseCategoryId' | 'name'>;
  register: {};
  'register/registered': {};
  'register/input_profile': {};
  faq: {};
  recruit: {};
  contact: {};
  privacy_policy: {};
  operating_company: {};
  login: {};
  logout: {};
  forgot_password: {};
  'forgot_password/input_password': {};
  mypage: {};
  'mypage/point_history': {};
  'mypage/charge_history': {};
  'mypage/profile': {};
  'mypage/profile/edit': {};
  'mypage/profile/edit_tel_number': {};
  'mypage/profile/edit_tel_number/confirm': {};
  'mypage/profile/edit_mail': {};
  'mypage/profile/edit_mail/confirm': {};
  'mypage/profile/edit_password': {};
  'mypage/profile/edit_magazine': {};
  'reraku_pay/login': {};
  'reraku_pay/login/forgot_password': {};
  'reraku_pay/login/forgot_password/input_password': {};
  'reraku_pay/register': {};
  'reraku_pay/register/input_profile': {};
  'reraku_pay/register/registered': {};
  '404': {};
  '500': {};
}>

export type PageName = keyof PageNames;

export type ReturnPageInfo = {
  name: string;
  href: string;
  external?: boolean;
  breadcrumb: Readonly<PageName[]>;
}

export type PageInfo<T extends PageName = any> = {
  name: string | ((variables: PageNames[T]) => string);
  href: string | ((variables: PageNames[T]) => string);
  external?: boolean;
  breadcrumb: Readonly<PageName[]>;
};

const defaultPageInfo: PageInfo = {
  name: '',
  href: '',
  external: false,
  breadcrumb: [],
};
// TODO: Next.jsがTS4.9に対応したら変えたい
// as const satisfies PageInfo;

const pageInfos: { [T in PageName]: PageInfo } = {
  'top': {
    ...defaultPageInfo,
    name: 'トップ',
    href: '/',
  },
  'guide': {
    ...defaultPageInfo,
    name: 'はじめての方',
    href: '/guide',
    breadcrumb: [
      'top',
    ],
  },
  'card': {
    ...defaultPageInfo,
    name: 'Re.Ra.Kuカード',
    href: '/card',
    breadcrumb: [
      'top',
    ],
  },
  'education': {
    ...defaultPageInfo,
    name: 'Re.Ra.Ku の教育',
    href: '/education',
    breadcrumb: [
      'top',
    ],
  },
  'about_rerakupay': {
    ...defaultPageInfo,
    name: 'Re.Ra.Ku PAY とは',
    href: '/about_rerakupay',
    breadcrumb: [
      'top',
    ],
  },
  'news': {
    ...defaultPageInfo,
    name: 'NEWS',
    href: '/news',
    breadcrumb: [
      'top'
    ],
  },
  'news/{id}': {
    ...defaultPageInfo,
    name: ({ title }) => `${title}`,
    href: ({ displayId }) => `/news/${displayId}`,
    breadcrumb: [
      'top',
      'news'
    ],
  },
  'column': {
    ...defaultPageInfo,
    name: 'コラム',
    href: '/column',
    breadcrumb: [
      'top'
    ],
  },
  'column/{id}': {
    ...defaultPageInfo,
    name: ({ title }) => `${title}`,
    href: ({ displayId }) => `/column/${displayId}`,
    breadcrumb: [
      'top',
      'column'
    ],
  },
  'brand': {
    ...defaultPageInfo,
    name: 'ブランド紹介',
    href: '/brand',
    breadcrumb: [
      'top'
    ],
  },
  'brand/reraku': {
    ...defaultPageInfo,
    name: 'Re.Ra.Ku とは',
    href: '/brand/reraku',
    breadcrumb: [
      'top',
      'brand'
    ],
  },
  'brand/spareraku': {
    ...defaultPageInfo,
    name: 'Spa Re.Ra.Ku とは',
    href: '/brand/spareraku',
    breadcrumb: [
      'top',
      'brand'
    ],
  },
  'brand/rerakupro': {
    ...defaultPageInfo,
    name: 'Re.Ra.Ku PRO とは',
    href: '/brand/rerakupro',
    breadcrumb: [
      'top',
      'brand'
    ],
  },
  'brand/rerakupro/triathlon': {
    ...defaultPageInfo,
    name: 'ホノルルトライアスロン Team Re.Ra.Ku',
    href: '/brand/rerakupro/triathlon',
    breadcrumb: [
      'top',
      'brand',
      'brand/rerakupro'
    ],
  },
  'brand/bellepoc': {
    ...defaultPageInfo,
    name: 'Bell Epoc とは',
    href: '/brand/bellepoc',
    breadcrumb: [
      'top',
      'brand'
    ],
  },
  'brand/ruamruam': {
    ...defaultPageInfo,
    name: 'RuamRuam とは',
    href: '/brand/ruamruam',
    breadcrumb: [
      'top',
      'brand'
    ],
  },
  'brand/katakori': {
    ...defaultPageInfo,
    name: '東京肩こりクリニック とは',
    href: '/brand/katakori',
    breadcrumb: [
      'top',
      'brand'
    ],
  },
  'blog': {
    ...defaultPageInfo,
    name: '店舗ブログ一覧',
    href: '/blog',
    breadcrumb: [
      'top',
    ],
  },
  'studio': {
    ...defaultPageInfo,
    name: '店舗検索',
    href: '/studio',
    breadcrumb: [
      'top',
    ],
  },
  'studio/search': {
    ...defaultPageInfo,
    name: '店舗一覧（検索結果）',
    href: '/studio/search',
    breadcrumb: [
      'top',
      'studio',
    ],
  },
  'studio/{slug}': {
    ...defaultPageInfo,
    name: ({ name }) => `${name}`,
    href: ({ slug }) => `/studio/${slug}`,
    breadcrumb: [
      'top',
      'studio',
    ],
  },
  'studio/{slug}/menu': {
    ...defaultPageInfo,
    name: 'メニュー',
    href: ({ slug }) => `/studio/${slug}/menu`,
    breadcrumb: [
      'top',
      'studio',
      'studio/{slug}',
    ],
  },
  'studio/{slug}/staff': {
    ...defaultPageInfo,
    name: 'スタッフ',
    href: ({ slug }) => `/studio/${slug}/staff
    `,
    breadcrumb: [
      'top',
      'studio',
      'studio/{slug}',
    ],
  },
  'studio/{slug}/message': {
    ...defaultPageInfo,
    name: 'お店からのメッセージ',
    href: ({ slug }) => `/studio/${slug}/message`,
    breadcrumb: [
      'top',
      'studio',
      'studio/{slug}',
    ],
  },
  'studio/{slug}/blog': {
    ...defaultPageInfo,
    name: '店舗ブログ一覧',
    href: ({ slug }) => `/studio/${slug}/blog`,
    breadcrumb: [
      'top',
      'studio',
      'studio/{slug}',
    ],
  },
  'studio/{slug}/blog/{id}': {
    ...defaultPageInfo,
    name: ({ title }) => `${title}`,
    href: ({ shopSlug, displayId }) => `/studio/${shopSlug}/blog/${displayId}`,
    breadcrumb: [
      'top',
      'studio',
      'studio/{slug}',
      'studio/{slug}/blog',
    ],
  },
  'studio/{slug}/booking': {
    ...defaultPageInfo,
    name: 'コース選択',
    href: ({ slug }) => `/studio/${slug}/booking`,
  },
  'studio/{slug}/booking/step2': {
    ...defaultPageInfo,
    name: 'スタッフ指名・日時選択',
    href: ({ slug }) => `/studio/${slug}/booking/step2`,
  },
  'studio/{slug}/booking/step3': {
    ...defaultPageInfo,
    name: '予約内容確認',
    href: ({ slug }) => `/studio/${slug}/booking/step3`,
  },
  'studio/{slug}/booking/complete': {
    ...defaultPageInfo,
    name: '予約が完了しました',
    href: ({ slug }) => `/studio/${slug}/booking/complete`,
  },
  'studio/pref_area/{prefectureAreaId}': {
    ...defaultPageInfo,
    name: ({ name }) => `${name}の店舗一覧`,
    href: ({ id }) => `/studio/pref_area/${id}`,
    breadcrumb: [
      'top',
      'studio',
    ],
  },
  'studio/pref/{prefectureId}': {
    ...defaultPageInfo,
    name: ({ name }) => `${name}の店舗一覧`,
    href: ({ id }) => `/studio/pref/${id}`,
    breadcrumb: [
      'top',
      'studio',
    ],
  },
  'studio/pref/{prefectureId}/city/{cityId}': {
    ...defaultPageInfo,
    name: ({ cityName }) => `${cityName}の店舗一覧`,
    href: ({ prefectureId, id }) => `/studio/pref/${prefectureId}/city/${id}`,
    breadcrumb: [
      'top',
      'studio',
      'studio/pref/{prefectureId}'
    ],
  },
  'studio/railroad/{railroadLineId}': {
    ...defaultPageInfo,
    name: ({ name }) => `${name}の店舗一覧`,
    href: ({ id }) => `/studio/railroad/${id}`,
    breadcrumb: [
      'top',
      'studio',
    ],
  },
  'studio/station/{railroadStationId}': {
    ...defaultPageInfo,
    name: ({ name }) => `${name}駅の店舗一覧`,
    href: ({ id }) => `/studio/station/${id}`,
    breadcrumb: [
      'top',
      'studio',
    ],
  },
  'studio/course/{courseId}': {
    ...defaultPageInfo,
    name: ({ name }) => `${name}の店舗一覧`,
    href: ({ id }) => `/studio/course/${id}`,
    breadcrumb: [
      'top',
      'studio',
    ],
  },
  'studio/course_category/{courseCategoryId}': {
    ...defaultPageInfo,
    name: ({ name }) => `${name}の店舗一覧`,
    href: ({ courseCategoryId }) => `/studio/course_category/${courseCategoryId}`,
    breadcrumb: [
      'top',
      'studio',
    ],
  },
  'register': {
    ...defaultPageInfo,
    name: '新規会員登録',
    href: '/register',
  },
  'register/registered': {
    ...defaultPageInfo,
    name: '新規会員登録',
    href: '/register/registered',
  },
  'register/input_profile': {
    ...defaultPageInfo,
    name: '新規会員登録',
    href: '/register/input_profile',
  },
  'faq': {
    ...defaultPageInfo,
    name: 'FAQ',
    href: '/faq',
    breadcrumb: [
      'top',
    ],
  },
  'recruit': {
    ...defaultPageInfo,
    name: '採用情報',
    href: 'https://seranabi.jp/recruit',
    external: true,
  },
  'contact': {
    ...defaultPageInfo,
    name: '問い合わせ',
    href: 'https://medirom.co.jp/contact/',
    external: true,
  },
  'privacy_policy': {
    ...defaultPageInfo,
    name: 'プライバシーポリシー',
    href: 'https://winginc.co.jp/privacy-policy/',
    external: true,
  },
  'operating_company': {
    ...defaultPageInfo,
    name: '運営会社',
    href: 'https://winginc.co.jp/',
    external: true,
  },
  'tokushoho': {
    ...defaultPageInfo,
    name: '特定商取引法',
    href: '/tokushoho',
    breadcrumb: [
      'top',
    ],
  },
  'login': {
    ...defaultPageInfo,
    name: 'ログイン',
    href: '/login',
    breadcrumb: [
      'top',
    ],
  },
  'logout': {
    ...defaultPageInfo,
    name: 'ログアウト',
    href: '/logout',
    breadcrumb: [
      'top',
    ],
  },
  'forgot_password': {
    ...defaultPageInfo,
    name: 'パスワードをお忘れの方',
    href: '/login/forgot_password',
    breadcrumb: [
      'top',
      'login',
    ],
  },
  'forgot_password/input_password': {
    ...defaultPageInfo,
    name: 'パスワードをお忘れの方',
    href: '/login/forgot_password/input_password',
    breadcrumb: [
      'top',
      'login',
    ],
  },
  'mypage': {
    ...defaultPageInfo,
    name: 'マイページ',
    href: '/mypage',
    breadcrumb: [
      'top',
    ],
  },
  'mypage/point_history': {
    ...defaultPageInfo,
    name: 'ポイント履歴',
    href: '/mypage/point_history',
    breadcrumb: [
      'top',
      'mypage',
    ],
  },
  'mypage/charge_history': {
    ...defaultPageInfo,
    name: 'チャージ履歴',
    href: '/mypage/charge_history',
    breadcrumb: [
      'top',
      'mypage',
    ],
  },
  'mypage/profile': {
    ...defaultPageInfo,
    name: '会員情報',
    href: '/mypage/profile',
    breadcrumb: [
      'top',
      'mypage',
    ],
  },
  'mypage/profile/edit': {
    ...defaultPageInfo,
    name: '会員情報編集',
    href: '/mypage/profile/edit',
    breadcrumb: [
      'top',
      'mypage',
      'mypage/profile',
    ],
  },
  'mypage/profile/edit_tel_number': {
    ...defaultPageInfo,
    name: '電話番号編集',
    href: '/mypage/profile/edit_tel_number',
    breadcrumb: [
      'top',
      'mypage',
      'mypage/profile',
    ],
  },
  'mypage/profile/edit_tel_number/confirm': {
    ...defaultPageInfo,
    name: '電話番号認証',
    href: '/mypage/profile/edit_tel_number/confirm',
    breadcrumb: [
      'top',
      'mypage',
      'mypage/profile',
    ],
  },
  'mypage/profile/edit_mail': {
    ...defaultPageInfo,
    name: 'メール編集',
    href: '/mypage/profile/edit_mail',
    breadcrumb: [
      'top',
      'mypage',
      'mypage/profile',
    ],
  },
  'mypage/profile/edit_mail/confirm': {
    ...defaultPageInfo,
    name: 'メール認証',
    href: '/mypage/profile/edit_mail/confirm',
    breadcrumb: [
      'top',
      'mypage',
      'mypage/profile',
    ],
  },
  'mypage/profile/edit_password': {
    ...defaultPageInfo,
    name: 'パスワード変更',
    href: '/mypage/profile/edit_password',
    breadcrumb: [
      'top',
      'mypage',
      'mypage/profile',
    ],
  },
  'mypage/profile/edit_magazine': {
    ...defaultPageInfo,
    name: 'メールマガジン編集',
    href: '/mypage/profile/edit_magazine',
    breadcrumb: [
      'top',
      'mypage',
      'mypage/profile',
    ],
  },
  'reraku_pay/login': {
    ...defaultPageInfo,
    name: 'Re.Ra.Ku PAY ログイン',
    href: '/reraku_pay/login',
    breadcrumb: [
      'top',
    ],
  },
  'reraku_pay/login/forgot_password': {
    ...defaultPageInfo,
    name: 'Re.Ra.Ku PAY パスワードを忘れた方へ',
    href: '/reraku_pay/login/forgot_password',
    breadcrumb: [
      'top',
      'reraku_pay/login'
    ],
  },
  'reraku_pay/login/forgot_password/input_password': {
    ...defaultPageInfo,
    name: 'Re.Ra.Ku PAY パスワードを忘れた方へ',
    href: '/reraku_pay/login/forgot_password/input_password',
    breadcrumb: [
      'top',
      'reraku_pay/login',
      'reraku_pay/login/forgot_password'
    ],
  },
  'reraku_pay/register': {
    ...defaultPageInfo,
    name: 'Re.Ra.Ku PAY 新規会員登録',
    href: '/reraku_pay/register',
    breadcrumb: [
      'top',
    ],
  },
  'reraku_pay/register/input_profile': {
    ...defaultPageInfo,
    name: 'Re.Ra.Ku PAY 新規会員登録',
    href: '/reraku_pay/register/input_profile',
    breadcrumb: [
      'top',
      'reraku_pay/register',
    ],
  },
  'reraku_pay/register/registered': {
    ...defaultPageInfo,
    name: 'Re.Ra.Ku PAY 新規会員登録',
    href: '/reraku_pay/register/registered',
    breadcrumb: [
      'top',
      'reraku_pay/register',
    ],
  },
  '404': {
    ...defaultPageInfo,
    name: 'ページが見つかりませんでした',
    href: '/404',
  },
  '500': {
    ...defaultPageInfo,
    name: 'システムエラーが発生しました',
    href: '/500',
  },
};
// TODO: Next.jsがTS4.9に対応したら変えたい
// as const satisfies { [T in PageName]: PageInfo<T> };

type GetPageInfoOptions = {
  withFullUrl?: boolean;
  params?: Record<string, string>;
  excludeFirstPageParam?: boolean;
}

const getWithFullUrl = (path: string, withFullUrl: GetPageInfoOptions['withFullUrl'] = false): string => {
  return withFullUrl ? `${ApplicationConfig.customerUrl}${path}` : path;
};

export const getPageInfo = <T extends PageName, V extends PageNames[T]>(
  pageName: T,
  variables: V,
  {
    withFullUrl = false,
    params = {},
    excludeFirstPageParam = false,
  }: GetPageInfoOptions = {},
): ReturnPageInfo => {
  const pageInfo: PageInfo<T> = pageInfos[pageName] as any;
  const href = (typeof pageInfo.href === 'function') ? pageInfo.href(variables) : pageInfo.href;
  const nextParams = excludeFirstPageParam ?
    { ...Object.fromEntries(Object.entries(params).filter(([k, v]) => !(k === 'page' && v === '1'))) }
    : params;
  const hrefWithParams = `${href}${buildUrlParamsString(nextParams)}`;

  return {
    ...pageInfo,
    name: (typeof pageInfo.name === 'function') ? pageInfo.name(variables) : pageInfo.name,
    href: getWithFullUrl(hrefWithParams, withFullUrl),
  };
};

// TODO: Next.jsがTS4.9に対応したら変えたい
// type BreadcrumbPageInfos<T extends keyof PageNames> = (typeof pageInfos)[T]['breadcrumb'][number];

// TODO: Next.jsがTS4.9に対応したら変えたい
// export const getBreadcrumbLinks = <T extends keyof PageNames, B extends BreadcrumbPageInfos<T>>(
export const getBreadcrumbLinks = <T extends keyof PageNames>(
  pageName: T,
  variables: PageNames[T],
  breadcrumbVariables: Partial<{ [K in PageName]: PageNames[K] }>
): ReturnPageInfo[] => {
  const breadcrumb = [...pageInfos[pageName].breadcrumb];
  if (breadcrumb.length === 0) return [];

  return breadcrumb
    .map((breadcrumbPageName: PageName) => {
      return getPageInfo(breadcrumbPageName, breadcrumbVariables[breadcrumbPageName] as PageInfo);
    })
    .concat(getPageInfo(pageName, variables));
};
